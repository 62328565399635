var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":_vm.dialogWidth,"scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t('app.buttons.add'))+" "+_vm._s(_vm.$t('app.juegos_informales.single'))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"rounded":"","color":"primary"},on:{"click":_vm.saveItem}},[_vm._v(" "+_vm._s(_vm.$t('app.buttons.save'))+" ")])],1)],1),_c('v-card-text',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var reset = ref.reset;
return [_c('form',[_c('v-list',{attrs:{"three-line":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.sucursales.single')))]),_c('v-list-item-subtitle',[_c('validation-provider',{attrs:{"name":_vm.$t('app.sucursales.single'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"placeholder":_vm.$t('app.sucursales.single'),"items":_vm.sucursales,"item-text":"nombre","item-value":"id","error-messages":errors,"required":""},on:{"change":_vm.onBranchSelected},model:{value:(_vm.item.sucursal_id),callback:function ($$v) {_vm.$set(_vm.item, "sucursal_id", $$v)},expression:"item.sucursal_id"}})]}}],null,true)})],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.canchas.single')))]),_c('v-list-item-subtitle',[_c('validation-provider',{attrs:{"name":_vm.$t('app.canchas.single'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"placeholder":_vm.$t('app.canchas.single'),"items":_vm.canchas,"item-text":"nombre","item-value":"id","error-messages":errors,"required":""},model:{value:(_vm.item.cancha_id),callback:function ($$v) {_vm.$set(_vm.item, "cancha_id", $$v)},expression:"item.cancha_id"}})]}}],null,true)})],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.headers.color')))]),_c('v-list-item-subtitle',[_c('validation-provider',{attrs:{"name":_vm.$t('app.headers.color'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-color-picker',{attrs:{"dot-size":"25","swatches-max-height":"200"},model:{value:(_vm.item.color),callback:function ($$v) {_vm.$set(_vm.item, "color", $$v)},expression:"item.color"}})]}}],null,true)})],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.headers.date')))]),_c('v-list-item-subtitle',[_c('v-dialog',{ref:"datePickerModalStart",attrs:{"persistent":"","width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":_vm.$t('app.headers.date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","readonly":"","error-messages":errors,"required":""},model:{value:(_vm.item.fecha),callback:function ($$v) {_vm.$set(_vm.item, "fecha", $$v)},expression:"item.fecha"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true),model:{value:(_vm.datePickerModalStart),callback:function ($$v) {_vm.datePickerModalStart=$$v},expression:"datePickerModalStart"}},[_c('v-date-picker',{attrs:{"locale":_vm.$store.state.lang},on:{"input":function($event){_vm.datePickerModalStart = false}},model:{value:(_vm.item.fecha),callback:function ($$v) {_vm.$set(_vm.item, "fecha", $$v)},expression:"item.fecha"}})],1)],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.headers.time')))]),_c('v-list-item-subtitle',[_c('v-dialog',{ref:"timePickerModal",attrs:{"return-value":_vm.item.hora,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.item, "hora", $event)},"update:return-value":function($event){return _vm.$set(_vm.item, "hora", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":_vm.$t('app.headers.time'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('app.headers.time'),"prepend-icon":"mdi-clock-outline","readonly":"","error-messages":errors,"required":""},model:{value:(_vm.item.hora),callback:function ($$v) {_vm.$set(_vm.item, "hora", $$v)},expression:"item.hora"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true),model:{value:(_vm.timePickerModal),callback:function ($$v) {_vm.timePickerModal=$$v},expression:"timePickerModal"}},[(_vm.timePickerModal)?_c('v-time-picker',{attrs:{"full-width":""},model:{value:(_vm.item.hora),callback:function ($$v) {_vm.$set(_vm.item, "hora", $$v)},expression:"item.hora"}},[_c('v-col',{staticClass:"d-flex justify-center"},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.timePickerModal = false}}},[_vm._v(" "+_vm._s(_vm.$t('app.buttons.cancel'))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.timePickerModal.save(_vm.item.hora)}}},[_vm._v(" "+_vm._s(_vm.$t('app.buttons.ok'))+" ")])],1)],1):_vm._e()],1)],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.headers.description')))]),_c('v-list-item-subtitle',[_c('validation-provider',{attrs:{"name":_vm.$t('app.headers.description'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"placeholder":_vm.$t('app.headers.description'),"error-messages":errors},model:{value:(_vm.item.descripcion),callback:function ($$v) {_vm.$set(_vm.item, "descripcion", $$v)},expression:"item.descripcion"}})]}}],null,true)})],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.headers.contact')))]),_c('v-list-item-subtitle',[_c('validation-provider',{attrs:{"name":_vm.$t('app.headers.contact'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":_vm.$t('app.headers.contact'),"error-messages":errors,"required":""},model:{value:(_vm.item.contacto),callback:function ($$v) {_vm.$set(_vm.item, "contacto", $$v)},expression:"item.contacto"}})]}}],null,true)})],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.headers.game_duration')))]),_c('v-list-item-subtitle',[_c('validation-provider',{attrs:{"name":_vm.$t('app.headers.game_duration'),"rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":_vm.$t('app.headers.game_duration'),"error-messages":errors,"required":"","type":"number","suffix":"min"},model:{value:(_vm.item.duracion_juego),callback:function ($$v) {_vm.$set(_vm.item, "duracion_juego", $$v)},expression:"item.duracion_juego"}})]}}],null,true)})],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('app.headers.phones'))+" "),_c('v-btn',{attrs:{"small":"","outlined":""},on:{"click":function($event){return _vm.item.telefonos.push('')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus-circle ")]),_vm._v(" "+_vm._s(_vm.$t('app.buttons.add'))+" ")],1)],1),_c('v-list-item-subtitle',_vm._l((_vm.item.telefonos),function(telefono,index){return _c('v-row',{key:("telefono-" + index),staticClass:"d-flex flex-grow-1 flex-shrink-0"},[_c('v-col',{staticClass:"d-flex flex-grow-1 flex-shrink-0"},[_c('validation-provider',{attrs:{"name":_vm.$t('app.headers.phone'),"rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":_vm.$t('app.headers.phone'),"error-messages":errors,"required":"","type":"number"},model:{value:(_vm.item.telefonos[index]),callback:function ($$v) {_vm.$set(_vm.item.telefonos, index, $$v)},expression:"item.telefonos[index]"}})]}}],null,true)})],1),_c('v-col',{staticClass:"d-flex flex-shrink-1 flex-grow-0"},[(index > 0)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.item.telefonos.splice(index, 1)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1):_vm._e()],1)],1)}),1)],1)],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }