<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    :max-width="dialogWidth"
    scrollable
  >
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar>
          <v-btn
            icon
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ $t('app.buttons.add') }} {{ $t('app.juegos_informales.single') }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            rounded
            color="primary"
            @click="saveItem"
          >
            {{ $t('app.buttons.save') }}
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <validation-observer
          ref="observer"
          v-slot="{ validate, reset }"
        >
          <form>
            <v-list
              three-line
            >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.sucursales.single') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.sucursales.single')"
                      rules="required"
                    >
                      <v-autocomplete
                        v-model="item.sucursal_id"
                        :placeholder="$t('app.sucursales.single')"
                        :items="sucursales"
                        item-text="nombre"
                        item-value="id"
                        :error-messages="errors"
                        required
                        @change="onBranchSelected"
                      />
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.canchas.single') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.canchas.single')"
                      rules="required"
                    >
                      <v-autocomplete
                        v-model="item.cancha_id"
                        :placeholder="$t('app.canchas.single')"
                        :items="canchas"
                        item-text="nombre"
                        item-value="id"
                        :error-messages="errors"
                        required
                      />
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.headers.color') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.headers.color')"
                      rules="required"
                    >
                      <v-color-picker
                        dot-size="25"
                        swatches-max-height="200"
                        v-model="item.color"
                      />
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.headers.date') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-dialog
                      ref="datePickerModalStart"
                      v-model="datePickerModalStart"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <validation-provider
                          v-slot="{ errors }"
                          :name="$t('app.headers.date')"
                          rules="required"
                        >
                          <v-text-field
                            v-model="item.fecha"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            :error-messages="errors"
                            required
                            v-on="on"
                          />
                        </validation-provider>
                      </template>
                      <v-date-picker
                        v-model="item.fecha"
                        :locale="$store.state.lang"
                        @input="datePickerModalStart = false"
                      />
                    </v-dialog>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.headers.time') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-dialog
                      ref="timePickerModal"
                      v-model="timePickerModal"
                      :return-value.sync="item.hora"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <validation-provider
                          v-slot="{ errors }"
                          :name="$t('app.headers.time')"
                          rules="required"
                        >
                          <v-text-field
                            v-model="item.hora"
                            :label="$t('app.headers.time')"
                            prepend-icon="mdi-clock-outline"
                            readonly
                            v-bind="attrs"
                            :error-messages="errors"
                            required
                            v-on="on"
                          />
                        </validation-provider>
                      </template>
                      <v-time-picker
                        v-if="timePickerModal"
                        v-model="item.hora"
                        full-width
                      >
                        <v-col class="d-flex justify-center">
                          <v-btn
                            text
                            color="primary"
                            @click="timePickerModal = false"
                          >
                            {{ $t('app.buttons.cancel') }}
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.timePickerModal.save(item.hora)"
                          >
                            {{ $t('app.buttons.ok') }}
                          </v-btn>
                        </v-col>
                      </v-time-picker>
                    </v-dialog>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.headers.description') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.headers.description')"
                      rules=""
                    >
                      <v-textarea
                        v-model="item.descripcion"
                        :placeholder="$t('app.headers.description')"
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.headers.contact') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.headers.contact')"
                      rules="required"
                    >
                      <v-text-field
                        v-model="item.contacto"
                        :placeholder="$t('app.headers.contact')"
                        :error-messages="errors"
                        required
                      />
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.headers.game_duration') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.headers.game_duration')"
                      rules="required|numeric"
                    >
                      <v-text-field
                        v-model="item.duracion_juego"
                        :placeholder="$t('app.headers.game_duration')"
                        :error-messages="errors"
                        required
                        type="number"
                        suffix="min"
                      />
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t('app.headers.phones') }}
                    <v-btn
                      small
                      outlined
                      @click="item.telefonos.push('')"
                    >
                      <v-icon left>
                        mdi-plus-circle
                      </v-icon>

                      {{ $t('app.buttons.add') }}
                    </v-btn>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <v-row
                      v-for="(telefono, index) in item.telefonos"
                      :key="`telefono-${index}`"
                      class="d-flex flex-grow-1 flex-shrink-0"
                    >
                      <v-col
                        class="d-flex flex-grow-1 flex-shrink-0"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          :name="$t('app.headers.phone')"
                          rules="required|numeric"
                        >
                          <v-text-field
                            v-model="item.telefonos[index]"
                            :placeholder="$t('app.headers.phone')"
                            :error-messages="errors"
                            required
                            type="number"
                          />
                        </validation-provider>
                      </v-col>
                      <v-col
                        class="d-flex flex-shrink-1 flex-grow-0"
                      >
                        <v-btn
                          v-if="index > 0"
                          icon
                          @click="item.telefonos.splice(index, 1)"
                        >
                          <v-icon>
                            mdi-delete
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </form>
        </validation-observer>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'JuegosInformalesAdd',
    data () {
      return {
        dialog: false,
        item: {
          sucursal_id: null,
          cancha_id: null,
          fecha: new Date().toISOString().substr(0, 10),
          hora: null,
          descripcion: null,
          contacto: null,
          telefonos: [''],
          color: {
            r: 224,
            g: 224,
            b: 224,
            a: 1,
          },
          duracion_juego: '',
        },
        canchas: [],
        datePickerModalStart: false,
        datePickerModalEnd: false,
        timePickerModal: false,
        sucursales: [],
      }
    },
    mounted () {
      EventBus.$on('juegos-informales-add', async () => {
        this.toggleLoader()
        this.sucursales = await this.getBranches()

        this.toggleLoader()
        this.dialog = true
      })
    },
    methods: {
      async onBranchSelected () {
        this.toggleLoader()

        this.canchas = await this.getCanchasSearch({
          filters: {
            sucursal_id: this.item.sucursal_id,
          },
        })

        this.toggleLoader()
      },
      async saveItem () {
        this.$refs.observer.validate()
          .then(async success => {
            if (!success) return

            this.toggleLoader()

            await this.$http.post(route('v1/informal-play'), this.item)
              .then(response => {
                if (response.body.code === 200) {
                  this.processSuccess(response)
                  this.item = {
                    cancha_id: null,
                    fecha: new Date().toISOString().substr(0, 10),
                    hora: null,
                    descripcion: null,
                    contacto: null,
                    telefonos: [''],
                    color: {
                      r: 224,
                      g: 224,
                      b: 224,
                      a: 1,
                    },
                    duracion_juego: '',
                  }

                  requestAnimationFrame(() => {
                    this.$refs.observer.reset()
                  })

                  this.dialog = false
                  EventBus.$emit('reload-items')
                } else {
                  this.processError(response)
                }
              }, error => {
                this.processError(error)
              })

            this.toggleLoader()
          })
      },
    },
  }
</script>

<style scoped>

</style>
